import { useGetWhiten } from "utils/colorHooks";
import SideBarLink, { SideBarLinkProps } from "./Link";

const BOX_SIZE = ".75em";
const PADDING_Y = ".25em";
const PADDING_L = "2.5rem";

export default function SideBarSubLink(props: SideBarLinkProps) {
  const getWhiten = useGetWhiten();
  return (
    <SideBarLink
      fontSize=".9em"
      fontWeight={300}
      pl={PADDING_L}
      py={PADDING_Y}
      position="relative"
      _before={{
        content: '" "',
        position: "absolute",
        top: `calc(${PADDING_Y} / 2 + 1.65em / 2 - ${BOX_SIZE} / 2)`,
        left: `calc(${PADDING_L} / 2 - ${BOX_SIZE} / 2 + .2em)`,
        boxSize: BOX_SIZE,
        backgroundColor: getWhiten("lightPink", 75),
      }}
      _activeLink={{
        color: "magenta.dark",
        fontWeight: 500,
        _before: {
          backgroundColor: "lightPink",
        },
      }}
      {...props}
    />
  );
}
